@charset "utf-8";

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: $color_body_bg;
  font-family: $base_font_family;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  @include body_fontsize($base_font_size);
  line-height: 1.4;
  color: $color_text;
  height: auto !important;
  overflow: hidden;
  @media ( max-width : 767px ) {
  // @include body_fontsize(12);
  }
}

main {
  overflow: hidden;
}

figure {
  margin: 0;
  text-align: center;

  figcaption {
    margin-top: 0.5em;
    text-align: left;
  }
}

a {
  color: $color_link;
  text-decoration: underline;

  &:hover {
    color: $color_link_hover;
    text-decoration: none;
  }

  .highlight & {
    color: $color_highlight_link;

    &:hover {
      color: $color_highlight_link_hover;
    }
  }
}

img {
  vertical-align: middle;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;

  &.circle {
    border-radius: 50%;
  }
}

p {
  margin: 0.5em 0 1em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;

  &:last-child {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin: 0.5em 0;
    padding-left: 2em;

    &:last-child {
      margin-bottom: 0.5em;
    }
  }
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;

  ol,
  ul {
    padding-left: 2em;
  }
}

ul.list-inline {
  @include list-horizontal(7px);
  margin-bottom: $base_font_size + px;
}

dl {
  margin: 0 0 1em;
  line-height: 1.6;

  &:last-child {
    margin-bottom: 0;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-left: 0;
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main {
  section {
    margin-bottom: 10%;

    &.highlight:last-child {
      margin-bottom: 0;
      padding-bottom: 10%;
    }
  }
}

.sp-mask {
  transition: none!important;
}

.highlight {
  padding: 4% 0;
  background: $color_highlight_bg;
  color: $color_highlight_text;
}
// Google翻訳バーより上のレイヤーに(layer phía trên bar Google dịch)
#swipebox-overlay {
  z-index: 10000002 !important;
}
@media ( min-width : 768px ) {
  p {
    margin: 0.5em 0 1em;
  }

  dl.dl-horizontal {
    overflow: hidden;

    dt {
      float: left;
      clear: left;
      margin-right: 1em;
    }

    dd {
      float: left;
    }
  }

  main {
    section {
      margin-bottom: 5%;

      &:last-child {
        margin-bottom: 7%;
      }

      &.highlight:last-child {
        margin-bottom: 0;
        padding-bottom: 7%;
      }
    }
  }
}
@media (max-width: 767px) {
  .tile {
    > * {
      height: auto!important;
    }
  }
}
@media (min-width: 768px) {
  .gutters {
    .row {
      &.row_inline {
        letter-spacing: -.40em;

        > .col {
          letter-spacing: normal;
          vertical-align: top;
          display: inline-block;
          float: none;
          margin-bottom: 5%;
        }

        .span_1 {
          &:nth-child(12n+1) {
            margin-left: 0;
          }
        }

        .span_2 {
          &:nth-child(6n+1) {
            margin-left: 0;
          }
        }

        .span_3 {
          &:nth-child(4n+1) {
            margin-left: 0;
          }
        }

        .span_4 {
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }

        .span_5 {
          &:nth-child(2n+1) {
            margin-left: 0;
          }
        }

        .span_6 {
          &:nth-child(2n+1) {
            margin-left: 0;
          }
        }

        .span_7 {
          width: 57.5%;
        }

        .span_8 {
          width: 66%;
        }

        .span_9 {
          width: 74.5%;
        }

        .span_10 {
          width: 83%;
        }

        .span_11 {
          width: 91.5%;
        }

        .span_12 {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .tile {
    > * {
      height: auto!important;
    }
  }
}

@if $side_column != 'right' {
  @media (min-width: 768px) {
    .gutters .span_9.column_main {
      float: right;
      width: 70.83%;
      margin-left: 4.16%;
    }

    .gutters .span_3.column_sub {
      float: left;
      width: 25%;
      margin-left: 0;
    }
  }
}

main {
  .column_main,
  .column_sub {
    overflow: hidden;
    min-height: 100px;

    .widget_header {
      @include fontsize(18);
      padding: 0.5em;
      margin-bottom: 0;

      +*:not(.calendar_wrap) {
        padding: 0;
        margin: 0 auto 1em;
      }

      +.epress_search,
      +.textwidget {
        padding: 10px 0;
      }
    }

    .widget_wrap {
       > ul,
      .menu {
        @include listclear();

        ul {
          @include listclear();
        }

        li {
          padding: 0.5em 0.7em;
          border-bottom: 1px solid #ccc;

          &:last-child {
            border-bottom: 0;
          }

          a {
            display: block;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .gutters .span_3.column_sub,
  .gutters .span_9.column_main {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub {
    .sidebar {
      letter-spacing: -.40em;

      .widget_wrap {
        letter-spacing: normal;
        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-left: 2%;

        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }
  }
}
// コンテナの最大幅(chiều rộng lớn nhất của container)
@media ( min-width : 768px ) {
  .container {
    max-width: $container+px;
  }
}
@media (min-width: 1024px) {
  .container {
    padding: 0 10px;
  }
}

.article_button {
  margin-top: 5%;
}
@media (min-width: 768px) {
  .article_detail,
  .article_list {
    &:not(.reverse) {
      .col {
        &.span_12 {
          +.span_4 {
            margin-left: 0;
          }
        }
      }
    }

    &.reverse {
      .col {
        &.span_12 {
          margin-left: 0;
        }
      }
    }
  }
}

.sidebar {
  visibility: hidden;

  > .widget_wrap {
    visibility: visible;
  }
}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

.sitemap {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 10px 0 10px 15px;
    border-bottom: 1px solid #ccc;
  }

  a {
    @include fontsize(16);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
@media ( min-width : 768px ) {
  .sitemap {
    li {}

    a {
      @include fontsize(22);
    }
  }
}

.parallax_h1 {
  height: 250px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: rgba(#000, .3);
  }

  h1 {
    color: #fff;

    &:after,
    &:before {
      background: #fff;
    }
  }
}
@media ( min-width : 768px ) {
  .parallax_h1 {
    height: 450px;
  }
}

.map_wide {
  #map_canvas,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: none;
  }
}

.slick-track {
  margin: 0 auto;
}

.col {
  dl {
    p {
      line-height: 1.4;
      margin: 0;
    }
  }
  p {
    line-height: 1.8;
  }
}
.container {
  padding: 0 10px;
  @media ( min-width : ($container + 20)+px ) {
    padding: 0;
  }
}

// webフォントサービス対策
#fb-root,
.fb-page {
  font-family: "lucida grande", tahoma, verdana, arial, "hiragino kaku gothic pro",meiryo,"ms pgothic",sans-serif !important;
}

#swipebox-overlay {
  background: rgba(#0d0d0d, .8);
}
