@charset "utf-8";
.article_detail {
  background: rgba($color_primary, .05);
  padding: 4%;
  margin-bottom: 4%;
  @include fontsize(15);
  .article_date {
    margin-bottom: 1em;
  }
  .article_thumbnail {
    float: left;
    margin: 0 2% 2% 0;
  }
  .article_content {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  // case
  .case_arrow {
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    display: block;
    position: relative;
    height: 245px;
    @media (max-width: 767px) {
      height: 80px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      margin: auto;
      width: 70px;
      height: 70px;
      border-top: 5px solid $color_primary;
      border-right: 5px solid $color_primary;
      transform: translate(0, -50%) rotate(45deg);
      transform-origin: left top;
      transition: .2s;
      @media (max-width: 767px) {
        left: 0;
        bottom: 25%;
        border-top: 5px solid $color_primary;
        border-right: 5px solid $color_primary;
        transform: translate(0, -50%) rotate(135deg);
        transform-origin: center;
      }
    }
  }
  .span_12 {
    margin: 2% 0;
  }
}

.epress_social {
  letter-spacing: -.40em;
  text-align: right;
  padding: 2%;
  >* {
    letter-spacing: normal;
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
  }
}

.epress_pager {
  overflow: hidden;
  margin-bottom: 5%;
  .epress_pager_prev {
    float: left;
    a {
      display: block;
      position: relative;
      padding-left: 15px;
      text-decoration: none;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 2px;
        margin: auto;
        width: 8px;
        height: 1px;
        background: $effect_arrow_color;
        transition: .2s;
      }
      &:before {
        transform: translateY(-50%) rotate(-45deg);
        top: calc(50% - 2px);
      }
      &:after {
        transform: translateY(-50%) rotate(-135deg);
        top: calc(50% + 3px);
      }
      &:hover {
        text-decoration: underline;
        &:before {
          left: 0;
        }
        &:after {
          left: 0;
        }
      }
    }
  }
  .epress_pager_next {
    float: right;
    a {
      display: block;
      position: relative;
      padding-right: 15px;
      text-decoration: none;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 2px;
        margin: auto;
        width: 8px;
        height: 1px;
        background: $effect_arrow_color;
        transform-origin: center;
        transition: .2s;
      }
      &:before {
        transform: translateY(-50%) rotate(45deg);
        top: calc(50% - 2px);
      }
      &:after {
        transform: translateY(-50%) rotate(135deg);
        top: calc(50% + 3px);
      }
      &:hover {
        text-decoration: underline;
        &:before {
          right: 0;
        }
        &:after {
          right: 0;
        }
      }
    }
  }
}


// コメント投稿フォーム
#commentform {
  background: transparent;
  padding: 2% 5%;
  border: 1px solid #333;
  #comment {
    width: 100%;
  }
  #submit {
    background: $color_primary;
    color: #fff;
    border: 0;
    @include fontsize(14);
    padding: 3px 10px;
    margin: 0 auto;
    display: table;
  }
  input[type="submit"] {
      background: $color_primary;
      color: #fff;
      border: 0;
      @include fontsize(14);
      padding: .3em 1em;
      margin: 0 auto;
      display: table;
      border-radius: 5px;
  }
}
@media ( min-width : 768px ) {
  // コメント投稿フォーム
  #commentform {
    #comment {
    }
    #submit {
      @include fontsize(18);
    }
    input[type="submit"] {
      @include fontsize(18);
    }
  }
}
