@charset "utf-8";

.shopinfo {
  figure {
    max-width: 80%;
    margin: 0 auto;
  }
  address {
    text-align: center;
    @include fontsize(20);
    font-style: normal;
  }
  dl {
    margin: 2em 0;
    line-height: 1.4;
    &:first-child {
      margin-top: 0;
    }
    dt {
      margin: 0 0 0.2em 0;
      font-weight: normal;
      &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 3px;
        margin-right: 0.3em;
        vertical-align: middle;
        background: $color-primary;
      }
    }
    dd {
      margin: 0 0 0.5em 1em;
      padding: 0;
    }
  }
  .map_container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 70%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    + p {
      margin-top: 2em;
    }
    &.map_height01 {
      padding-top: 30%;
      @media ( max-width : 767px ) {
        padding-top: 50%;
      }
    }
    &.map_height02 {
      padding-top: 65%;
      @media ( max-width : 767px ) {
        padding-top: 50%;
      }
    }
  }
}

.map_wide {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// スクロール中のマップ引っ掛かりを防止(phòng tránh những trường hợp xấu xảy ra khi scroll map)
body.scroll .googlemap {
  pointer-events: none;
}

@media ( min-width : 768px ) {

.shopinfo {
  figure {
    max-width: 100%;
  }
  address {
    margin-bottom: 15px;
    text-align: left;
    @include fontsize(26);
    line-height: 1;
  }
  dl.dl-horizontal {
    margin: 10px 0;
    dd {
      margin-bottom: 0.3em;
    }
  }
  .span12 {
    .map_container {
      padding-top: 50%;
    }
  }
}

.map_wide {
  padding-top: 420px;
  iframe {
  }
}

}
