@charset "utf-8";

.bf-af {
  .before {

  }
}

@media ( max-width : 767px ) {

.bf-af {
  .row:not(:last-child) {
    margin-bottom: 10%;
  }
  .before {
    padding-bottom: 16%;
    background: url(../img/arrow_down.svg) center bottom no-repeat;
    background-size: 20%;
    background-size: 45px auto;
  }
  .after {
    &:not(:last-child) {
      margin-bottom: 10%;
    }
  }
}


}

@media ( min-width : 768px ) {

.bf-af {
  display: table;
  table-layout: fixed;
  width: 100%;
  .before, .after {
    display: table-cell;
    vertical-align: top;
  }
  .before span {
    display: block;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      background: url(../img/arrow_right.svg) center center no-repeat;
      background-size: 100%;
    }
  }
  &.col2 {
    .before {
      width: 55%;
      padding-right: 10%;
    }
    .after {
      width: 45%;
    }
    .before span {
      &:after {
        width: 10%;
        right: -16%;
      }
    }
  }
  &.col4 {
    .before:nth-child(1) {
      width: 24%;
      padding-left: 0;
      padding-right: 2%;
    }
    .after:nth-child(2) {
      width: 26%;
      padding-left: 3%;
      padding-right: 1%;
    }
    .before:nth-child(3) {
      width: 26%;
      padding-left: 1%;
      padding-right: 3%;
    }
    .after:nth-child(4) {
      width: 24%;
      padding-left: 2%;
      padding-right: 0;
    }
    .before span {
      &:after {
        width: 13%;
        right: -18%;
      }
    }
  }
}

}
