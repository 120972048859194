@charset "utf-8";

.slider-pro.contents {
  overflow: hidden;
  margin: 0 auto !important;
  .sp-caption-container {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    h3 {
      margin: 0 0 15px;
      @include fontsize(14);
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      @include fontsize(13);
    }
  }
  &.sp-horizontal .sp-previous-arrow {
    left: 10px;
    margin-top: 180px;
  }
  &.sp-horizontal .sp-next-arrow {
    right: 10px;
    margin-top: 180px;
  }
  .sp-next-arrow:after,
  .sp-next-arrow:before,
  .sp-previous-arrow:after,
  .sp-previous-arrow:before {
    background-color: $color_contents_slider_arrow;
  }
}

@media ( max-width : 767px ) {

.slider-pro.contents {
  .sp-arrow {
    width: 10px;
    height: 15px;
  }
}

}

@media ( min-width : 768px ) {

.slider-pro.contents {
  .sp-caption-container {
    width: $contents_slider_image_width;
    min-height: $contents_slider_caption_minheight;
    margin: 10px auto 0;
    border-right: $style_contents_slider_caption_border;
    border-left: $style_contents_slider_caption_border;
    h3 {
    }
  }

  $arrow_v_margin: $contents_slider_image_height / 2 + $contents_slider_caption_minheight / 2 + $contents_slider_arrow_width * 0.75 + 10;
  $arrow_h_margin: ($contents_slider_image_width * 0.5 + $contents_slider_arrow_width / 2) * -1;
  .sp-arrow {
    width: $contents_slider_arrow_width;
    height: $contents_slider_arrow_width * 1.5;
  }
  &.sp-horizontal .sp-previous-arrow {
    left: 50%;
    margin-top: $arrow_v_margin;
    margin-left: $arrow_h_margin;
  }
  &.sp-horizontal .sp-next-arrow {
    right: 50%;
    margin-top: $arrow_v_margin;
    margin-right: $arrow_h_margin;
  }
}

}
