@charset "utf-8";

// ======== All Device

$color_grid_bg:(
  #e74c3c,
  #3498db,
  #f1c40f,
  #1abc9c,
  #9b59b6,
  #34495e,
  #2ecc71
);

$color_grid_text: #fff;
$color_grid_arrow: #fff;

.grid {

  display: block;
  margin: 0;

  &.row, .row,
  &.col, .col {
    margin: 0;
  }

  .has-child-grid & {
    @include flexbox;
    @include justify-content(center);
    @include align-items(stretch);
    .col {
      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      text-align: center;
      &.span_12 {
        padding: 20px 0;
      }
    }
  }

  .grid-arrow {
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: -15px;
      bottom: -15px;
      width: 30px;
      height: 30px;
      background-color: $color_grid_arrow;
      transform: rotate(45deg);
      transition: .3s;
    }
  }

  p {
    margin: 0;
    padding: 0.2em 0;
  }

  a {
    color: $color_grid_text;
    text-decoration: none;
    transition: .3s;
    > [style^="background-image"], img {
      transition: .3s;
    }
    &:hover {
      > [style^="background-image"], img {
        opacity: 0.6;
      }
    }
  }

  @if length($color_grid_bg) >= 1 {
    @for $i from 1 through length($color_grid_bg) {
      .grid-bg#{$i} {
        background-color: nth($color_grid_bg, $i);
        &:hover {
          background-color: lighten(nth($color_grid_bg, $i), 8%);
          .grid-arrow:after {
            background-color: darken(nth($color_grid_bg, $i), 8%);
          }
        }
      }
    }
  }

}

.ie .grid {
  .grid-arrow:after {
    display: none;
  }
}


// ======== Tablet & PC
@media ( min-width : 768px ) {

.grid {
  @include flexbox;
  @include justify-content(center);
  @include align-items(stretch);
  .col {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    // float: none;
    text-align: center;
      &.span_12 {
        padding: 0;
      }
    &.has-child-grid > * {
      @include flex-grow(1);
    }
  }

  p {
    margin: 0;
    padding: 0.2em 0;
  }

}

} // @media
