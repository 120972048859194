@charset "utf-8";

.feature {
  color: #fff;
  h3 {
    margin: 0;
    text-align: center;
    color: #fff;
    @include fontsize(22);
  }
  img {
    width: 100%;
  }
  .col {
    display: table;
    table-layout: fixed;
    &:not(:last-child) {
      margin-bottom: 0;
    }
    &:nth-child(1) {
      background: #49afe6;
    }
    &:nth-child(2) {
      background: #8ed95d;
    }
    &:nth-child(3) {
      background: #f6aa48;
    }
    &:nth-child(4) {
      background: #f98282;
    }
    .textbox {
      display: table-cell;
      width: 70%;
      padding: 1em 3%;
      vertical-align: middle;
    }
    figure {
      display: table-cell;
      width: 30%;
      vertical-align: middle;
      &:first-child {
        img {
          text-align: left;
        }
      }
      &:last-child {
        img {
          text-align: right;
        }
      }
    }
  }
}


@media ( min-width : 768px ) {

.feature {
  h3 {
    margin: 10px 0 15px;
    @include fontsize(26);
  }
  img {
    // width: auto;
  }
  .col {
    display: block;
    table-layout: auto;
    .textbox {
      display: block;
      width: auto;
      height: 250px;
      padding: 10px;
      @include fontsize(13);
    }
    figure {
      display: inline;
      width: auto;
      vertical-align: middle;
    }
  }
}

}