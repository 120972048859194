@charset "utf-8";

$wow: false;

h1:not(.title) {
  text-align: left;
  position: relative;
  @include fontsize(36);
  font-weight: bold;
  padding: 0 0 .6em;
  color: $color_h1_text;
  margin: 0 0 .7em;
  line-height: 1;
  @media ( max-width : 767px ) {
    @include fontsize(24);
    padding: 0 0 .7em;
  }
  span {
    position: absolute;
    left: 0;
    bottom: 0;
    @include fontsize(16);
    color: $color_text;
    font-weight: normal;
    @media ( max-width : 767px ) {
      @include fontsize(14);
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    // content: '';
    // display: block;
    // position: absolute;
    // bottom: 0;
    // left: 50%;
    // margin: auto;
    // transform: translateX(-50%);
    // width: 120px;
    // height: 4px;
    // background: $color_h1_text;
    // transition: 1s .5s;
    // @media ( max-width : 767px ) {
    //   width: 80px;
    // }
  }
}
h2 {
  text-align: center;
  margin: 0 auto .8em;
  position: relative;
  border: 0;
  padding: 0 0 .8em;
  word-wrap: break-word;
  font-weight: bold;
  @include fontsize(28);
  color: $color_h2_text;
  line-height: 1;
  @media ( max-width : 767px ) {
    @include fontsize(20);
  }
  span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    @include fontsize(12);
    font-weight: normal;
    color: #aacd06;
  }
  @if $wow == true {
    opacity: 0;
    transition: .5s;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  // &:after,
  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   height: 2px;
  //   background: #000;
  //   @if $wow == true {
  //     opacity: 0;
  //     width: 0;
  //     transition: 1s .3s;
  //   }
  //   @else {
  //     width: 50vw;
  //   }
  // }
  // &:before {
  //   right: calc(100% + 15px);
  // }
  // &:after {
  //   left: calc(100% + 15px);
  // }
}
@if $wow == true {
  h2 {
    &.animated {
      opacity: 1;
      &:after,
      &:before {
        opacity: 1;
        width: 50vw;
      }
    }
  }
}

h3 {
  margin: 0 0 .5em;
  padding: 0;
  color: $color_h3_text;
  font-weight: bold;
  @include fontsize(20);
  position: relative;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   display: block;
  //   width: 40px;
  //   height: 2px;
  //   background: $color_h3_text;
  //   @media ( max-width : 767px ) {
  //     width: 20px;
  //   }
  // }
}
h4 {
  margin: 0 0 1.5em;
  padding-left: 1em;
  @include fontsize(16);
  position: relative;
  color: $color_h4_text;
  font-weight: normal;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 2px;
    margin: auto;
    background: $color_h4_text;
  }
}
.widget_wrap {
  .widget_header {
    @include fontsize(16);
    color: $color_text;
    padding: .7em 0 .7em 1em;
    background: rgba($color_primary, .05);
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  h3 {
    @include fontsize(16);
  }
  h4 {
    @include fontsize(14);
  }
  .widget_wrap {
    .widget_header {
      @include fontsize(14);
    }
  }
}
