@charset "utf-8";
.article_list {
  padding: .5em;
  @media (min-width: 768px) {
    padding: 1em;
  }
  position: relative;
  overflow: hidden;
  &:nth-child(even) {
    background: $color_highlight_bg;
  }
  &:before {
    $size: 20;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: $size+px;
    height: $size+px;
    background: transparent;
    transform: translate(0 - ($size/2)+px, 0 - ($size/2)+px) rotate(45deg);
    transition: .3s;
  }
  &:hover {
    &:before {
      background: $color_primary;
    }
  }

  // case
  .case_arrow {
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    display: block;
    position: relative;
    height: 245px;
    @media (max-width: 767px) {
      height: 80px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      margin: auto;
      width: 70px;
      height: 70px;
      border-top: 5px solid $color_primary;
      border-right: 5px solid $color_primary;
      transform: translate(0, -50%) rotate(45deg);
      transform-origin: left top;
      transition: .2s;
      @media (max-width: 767px) {
        left: 0;
        bottom: 25%;
        border-top: 5px solid $color_primary;
        border-right: 5px solid $color_primary;
        transform: translate(0, -50%) rotate(135deg);
        transform-origin: center;
      }
    }
  }
  .span_12 {
    margin: 2% 0;
  }
}

.pagination {
  >* {
    display: inline-block;
    padding: .5em;
    border: 1px solid #ccc;
  }
  a {
    text-decoration: none;
    background: $color_highlight_bg;
    transition: .3s;
    &:hover {
      background: #fff;
    }
  }
  span {
  }
}
