@charset "utf-8";

.movie {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  &.ar16to9 {
    padding-top: 56.25%;
  }
  &.ar4to3 {
    padding-top: 75%;
  }
}
