@charset "utf-8";

.wide_image {
  img {
    width: 100%;
    vertical-align: top;
  }
}

@media ( min-width : 768px ) {

.wide_image {
  div {
    margin: 0 auto;
  }
}

} // @media