@charset "utf-8";

.parallax {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  &.ios {
    // background-size: 100% 100%;
    background-attachment: scroll;
  }
  .parallax_contents {
    h1, .h1 {
      margin: 0;
      padding: 1em 0;
    }
    @include v-center();
  }
}
$parallax_length: length($parallax_height_sp);
@if $parallax_length >= 1 {
  @for $i from 1 through $parallax_length {
    .parallax_#{$i} {
      height: nth($parallax_height_sp, $i);
      background-image: nth($parallax_img, $i);
    }
  }
}

@media ( min-width : 768px ) {

.parallax {
}
@if $parallax_length >= 1 {
  @for $i from 1 through $parallax_length {
    .parallax_#{$i} {
      height: nth($parallax_height_pc, $i);
    }
  }
}

} // @media