@charset "utf-8";

header {
  .global_nav {
    > ul {
      > li {
        // 2階層目(sub page thứ 2)
        &.has_under {
          > a {
            position: relative;

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              right: 6%;
              margin: auto;
              width: 10px;
              height: 10px;
              border-top: 2px solid $color_navlink_sp;
              border-right: 2px solid $color_navlink_sp;
              transform: translate(0, -50%) rotate(135deg);
              transition: 0.2s;
            }

            &.menu_under_open {
              &:after {
                transform: translate(0, 0) rotate(-45deg);
              }
            }
          }
        }

        > ul {
          @include listclear();
          display: none;

          > li {
            border-bottom: 1px solid $color_gnav_border_sp;
          }
        }
      }
    }
  }
}
@media ( min-width : $header_break_point+px ) {
  header {
    .global_nav {
      > ul {
        @if $header_menu_type == 'B' {
          position: relative;
          top: auto;
          z-index: 1;
        }

        > li {
          &.has_under {
            position: relative;

            @if $header_menu_type == 'C' {
              position: relative;
            }

            > a {
              // padding-right: 1em;
              &:after {
                border-color: $color_navlink_pc;
                width: 6px;
                height: 6px;
                content: none;
              }

              &:after {
                transform: translate(0, -50%) rotate(135deg);
              }

              &.menu_under_open {
                &:after {
                  transform: translate(0) rotate(-45deg);
                }
              }
            }

            > ul {
              position: absolute;
              width: 654px;
              top: 200%;
              right: -20px;
              margin: auto;
              letter-spacing: -.40em;
              z-index: 52;

              &:before {
                content: "";
                position: absolute;
                bottom: 100%;
                right: 45px;
                margin: auto;
                width: 0;
                border-right: 20px solid transparent;
                border-top: 20px solid transparent;
                border-left: 20px solid transparent;
                border-bottom: 20px solid $color_gnav_bg;
              }

              > li {
                letter-spacing: normal;
                display: inline-block;
                vertical-align: middle;
                transition: background 0.3s;
                width: calc(100% / 5);

                &:not(:last-child) {
                  border-right: 1px solid #00a19e;
                }
                @for $i from 1 through 5 {
                  &:nth-child(#{$i}) {
                    background: $color_primary url("../img/pict_00#{$i}.png") no-repeat center 30px;
                    background-size: auto 45px!important;

                    &:hover {
                      background: #aacd06 url("../img/pict_00#{$i}.png") no-repeat center 30px;
                    }
                  }
                }

                @if $header_menu_type == 'C' {
                  width: 100%;
                }
                text-align: center;
                border-bottom: 0;
                padding: 0;

                a {
                  padding: 78px 0 20px;
                  background: transparent;
                  @include fontsize(12);
                }
              }
            }
          }
        }
      }
    }
  }
}

@if $header_type == 'bottom' {
  @media (min-width: $header_break_point+px) {
    body.home {
      header {
        &.low {
          .global_nav > ul > li.has_under {
            > a {
              &:before {
                transform: translateY(-50%) rotate(45deg);
              }

              &:after {
                transform: translateY(-50%) rotate(-45deg);
              }

              &.menu_under_open {
                &:before {
                  transform: translateY(-50%) rotate(-45deg);
                }

                &:after {
                  transform: translateY(-50%) rotate(45deg);
                }
              }
            }

            > ul {
              top: auto;
              bottom: 100%;
            }
          }
        }

        &.high {
          .global_nav > ul > li.has_under {
            > a {
              &:before {
                transform: translateY(-50%) rotate(-45deg);
              }

              &:after {
                transform: translateY(-50%) rotate(45deg);
              }

              &.menu_under_open {
                &:before {
                  transform: translateY(-50%) rotate(45deg);
                }

                &:after {
                  transform: translateY(-50%) rotate(-45deg);
                }
              }
            }

            > ul {
              top: 100%;
              bottom: auto;
            }
          }
        }
      }
    }
  }
}
