@charset "utf-8";
/*
 * ボタン矢印(mũi tên button)
 */
.add_arrow {
  display: block;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 5%;
    width: 0;
    height: 0;
    transform: translateY(-50%);
    margin: auto;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 3px solid #fff;
    border-right: 3px solid transparent;
    transition: .2s;
  }

  &:hover {
    &:after {
      right: 3%;
    }
  }
}
/*
 * moreボタン雛形 (hình dạng button more)
 */
.more {
  display: inline-block;
  overflow: hidden;
  margin: auto;

  a {
    display: inline-block;
    letter-spacing: normal;
    border: 1px solid $effect_more_color;
    border-radius: 30px;
    color: #fff;
    background: $effect_more_color;
    padding: 0.6em 1.6em 0.6em 1.3em;
    margin-bottom: 1px;
    text-decoration: none;
    @include fontsize(14);
    @media ( min-width : 768px ) {
       @include fontsize(16);
    }
    &:hover {
      background: $color_secondary;
      border-color: $color_secondary;
    }
  }
}
/*
 * ボタンエフェクト(button effect)
 */
@media ( min-width : 768px ) {
  .effect01 {
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 140%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: -1;
      background: $effect_more_color;
      opacity: 0.3;
      transform: translateX(-120%) skewX(15deg);
      transition: transform 0.3s;
    }

    &:hover {
      @if $design_concept == 'material' {
        box-shadow: 0 0 30px 0 rgba(0,0,0,.3);
      }

      &:before {
        transform: translateX(-15%) skewX(15deg);
      }
    }
  }
}
