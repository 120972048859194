@charset "UTF-8"; // 管理用　ここの値は基本的に触らない
$color_primary: #083388 !default;
$color_text: #1b1b1b !default;
$design_concept: 'material' !default;
$container: 1200 !default; // 設定ファイルここから

#biz_calendar,
#biz_calendar1,
#biz_calendar2 {
  max-width: none;
  margin: 2% auto 0;
  font-family: inherit;
  padding: 0.3em 1em;
  background: $color_highlight_bg;
  @include fontsize(18);

  p {
    margin: 0;
    line-height: 170%;
    @include fontsize(12);
    text-align: left;

    span {
      &.boxholiday {
        background-color: #ffb0c3;
        padding-left: 25px;
        margin-right: 10px;
      }

      &.boxeventday {
        background-color: #64BDEB;
        padding-left: 25px;
        margin-right: 10px;
      }
    }
  }

  table {
    &.bizcal {
      margin: 10px 0;
      width: 100%;
      table-layout: fixed;
      border: none;
      line-height: 1.6;
      cursor: default;
      vertical-align: middle;
      @include fontsize(18);
      border-collapse: collapse;
      border-spacing: 1px;
      background: transparent;

      tr {
        &:not(:first-child) {
          > * {
            &:not(.holiday):not(.eventday):not(.today) {
              background: #fff;
            }
          }
        }
      }

      th {
        text-align: center;
        border-width: 0;
        padding: 0.5em 0.2em;
        color: $color_text;
        background-color: #DEDEDE;

        &:first-child {
          color: red;
        }

        &:nth-last-of-type(1) {
          color: blue;
        }
      }

      td {
        text-align: center;
        border: 1px solid rgba(0, 57, 122, 0.3);
        padding: 0.5em 0.2em;
        color: $color_text;
      }

      .calmonth {
        text-align: left;
        font-weight: bold;
        padding: 2px 7px;
        border: none;
      }

      .calbtn {
        border: none;
        cursor: pointer;
      }

      .holiday {
        background-color: #ffb0c3;
        color: red;
      }

      .eventday {
        background-color: #64BDEB;
        color: #ffffff;
      }

      .eventday a {
        color: #ffffff;
      }

      .today {
        font-weight: bold;
        text-decoration: underline;
        color: $color_text;
      }

      .today a {
        font-weight: bold;
        color: $color_text;
      }

      img {
        vertical-align: middle;
        box-shadow: none;
        margin: 0;
      }

      .down-img {
        text-align: right;
      }

      .today-img {
        text-align: center;
      }

      .up-img {
        text-align: left;
      }
    }
  }
}

#biz_calendar {
  table {
    &.bizcal {
      td,
      th {
        padding: 0.5em 0.2em;
      }
    }
  }
}
@mixin calendarMobile {
  #biz_calendar,
  #biz_calendar1,
  #biz_calendar2 {
    @include fontsize(12);

    p {
      span {
        &.boxholiday {
          background-color: #ffb0c3;
          padding-left: 25px;
          margin-right: 10px;
        }

        &.boxeventday {
          background-color: #64BDEB;
          padding-left: 25px;
          margin-right: 10px;
        }
      }
    }

    table {
      &.bizcal {
        @include fontsize(12);

        td,
        th {
          padding: 0.3em 0.2em;
        }
      }
    }
  }
}

.column_sub {
  @include calendarMobile();
}

@media ( max-width : 767px ) {
  @include calendarMobile();
}
