@charset "utf-8";

.guide {
  figure {
    margin-bottom: 1em;
    img {
      width: auto;
      max-width: 100%;
    }
  }
  .message {
    .contents_link {
      text-align: center;
    }
  }
}


@media ( min-width : 768px ) {

  .guide {
    position: relative;
  	display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: -1em;
    figure {
      flex: 1;
      margin-bottom: 0;
      min-width: 0; // Firefox bug?
    }
    .message {
      flex: 3;
      padding-left: 2%;
      padding-bottom: 45px;
      &.text-only {
        padding-bottom: 0;
      }
      h2 {
        margin-bottom: 1em;
      }
      .contents_link {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }

    &.reverse {
      flex-direction: row-reverse;
      .message {
        padding-left: 0;
        padding-right: 2%;
      }
    }

  }

  .ie .guide {
    display: table;
    figure {
      display: table-cell;
      width: 23.5%;
      vertical-align: middle;
    }
    .message {
      display: table-cell;
      width: 74.5%;
      padding-left: 2%;
      vertical-align: middle;
    }
    &.reverse {
      .message {
        padding-left: 2%;
        padding-right: 0;
      }
    }
  }

}
