@charset "utf-8";

// ======== All Device

// top
.sec_01 {
  padding: 3% 0;
  p:not(.more) {
    line-height: 2;
  }
  .more {
    a {
      width: 248px;
    }
  }
}

.sec_02 {
  padding: 1.8% 0;
  background: url(../img/top_bg_img001.jpg) no-repeat center center;
  background-size: cover;
  .bnr_circle {
    .col {
      width: 100%;
      position: relative;
      padding-top: 18.8%;
      border-radius: 50%;
      background: $color_primary;
      transition: .3s;
      &:hover {
        background: rgba($color_secondary, .85);
      }
      @media ( min-width : 768px ) {
        width: 18.8%;
        margin-left: 1.5%;
        &:first-child {
          margin-left: 0;
        }
      }
      @media ( max-width : 767px ) {
        max-width: 188px;
        height: 188px;
        margin: 0 auto 4%;
      }
    }
    a {
      display: block;
    }
    .bnr_circle_box {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      margin: auto;
      color: #fff;
      text-align: center;
      p {
        line-height: 1.3;
        @include fontsize(16);
        @media ( max-width : 880px ) {
          @include fontsize(12);
        }
        @media ( max-width : 767px ) {
          @include fontsize(16);
        }
      }
    }
    figure {
      +p {
        margin-top: .7em;
      }
    }
  }
}

.sec_03 {
  padding: 3% 0;
  .bnr_content {
    position: relative;
    &:hover {
      .bnr_content_text {
        background: rgba($color_secondary, .85);
        height: 100%;
      }
    }
    .bnr_content_text {
      display: block;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      width: 100%;
      max-width: 490px;
      height: 40%;
      text-align: center;
      background: rgba($color_primary, .85);
      line-height: 1.2;
      transition: .3s;
      @media ( max-width : 800px ) {
        height: 46%;
      }
      a {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      color: #fff;
      text-decoration: none;
      @include fontsize(20);
      @media ( max-width : 1000px ) {
        @include fontsize(16);
      }
        span {
          @include fontsize(12);
        }
      }
    }
  }
}

// ページタイトル
.sec_h1 {
  @media ( max-width : 767px ) {
    padding: 9% 0 !important;
  }
  p {
    @include fontsize(16);
    line-height: 1.7;
    font-weight: bold;
    @media ( max-width : 767px ) {
      @include fontsize(14);
    }
  }
  &.page_company {
    background: url(../img/company_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
  }
  &.page_dental {
    background: url(../img/dental_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
  }
  &.page_haruene {
    background: url(../img/haruene_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
  }
  &.page_seo {
    background: url(../img/seo_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
  }
  &.page_support {
    background: url(../img/support_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
  }
  &.page_contact {
    background: url(../img/contact_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
  }
  &.page_recruit {
    background: url(../img/recruit_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
    p {
      text-shadow: 2px 2px 2px #fff, 2px -2px 2px #fff, -2px 2px 2px #fff, -2px -2px 2px #fff;
      font-weight: bold;
    }
  }
  &.page_policy {
    background: url(../img/policy_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 5.8% 0;
  }
  &.page_sitemap {
    background: url(../img/company_bg_img001.jpg) no-repeat center center;
    background-size: cover;
    padding: 3% 0;
    h1 {
      margin: 0;
    }
  }
}

// 会社紹介
.sec_company01 {
  .sec_company01_text01 {
    @include fontsize(20);
    line-height: 1.4 !important;
  }
}

.text_name01 {
  @include fontsize(18);
  line-height: 1.6 !important;
  span {
    @include fontsize(22);
    font-weight: bold;
  }
}

.link_content01 {
  .container {
    border: 5px solid #dff2ef;
    padding: 2em;
    @media ( max-width : 767px ) {
      padding: 1em;
    }
  }
  .link_img {
    width: 100%;
    @media ( min-width : 768px ) {
      width: 17.130620985010706%;
    }
  }
  .link_text {
    width: 100%;
    @media ( min-width : 768px ) {
      width: 80.36937901498929%;
      margin-left: 2.5%;
    }
  }
}


// リンク　ブランク
.link_blank {
  text-decoration: none;
  background: url(../img/link_icon001.png) no-repeat left center;
  padding-left: 16px;
  &:hover {
    text-decoration: underline;
  }
}

.item_content01 {
  p {
    font-weight: bold;
    padding: .6em 0;
    @media ( min-width : 768px ) {
      @include fontsize(20);
    }
  }
  .color_primary {
    color: #fff;
    background: $color_primary;
  }
  .color_secondary {
    color: #fff;
    background: $color_secondary;
  }
  @media ( min-width : 768px ) {
    .span_3 {
      width: 22%;
      margin-left: 4%;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

h3 {
  +.item_content01 {
    margin-top: 2em;
    @media ( max-width : 767px ) {
      margin-top: 1em;
    }
  }
}

.item_content02 {
  .content_title {
    font-weight: bold;
    padding: .6em 0;
    margin: 0 0 0.5em;
    @media ( min-width : 768px ) {
      @include fontsize(20);
    }
  }
  .color_primary {
    color: #fff;
    background: $color_primary;
  }
  .content_text01 {
    background: url(../img/dental_bg_img002.png) no-repeat center center;
    background-size: contain;
  }
  .content_text02 {
    background: url(../img/dental_bg_img003.png) no-repeat center center;
    background-size: contain;
  }
  .content_text03 {
    background: url(../img/dental_bg_img004.png) no-repeat center center;
    background-size: contain;
  }
}

.circle_content01 {
  .span_4 {
    @media ( min-width : 768px ) {
      width: 30%;
      margin-left: 5%;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .col {
    position: relative;
    text-align: center;
    padding-top: 30%;
    border-radius: 50%;
    background: $color_primary;
    @media ( max-width : 767px ) {
      width: 100%;
      max-width: 300px;
      height: 300px;
      margin: 0 auto 3%;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 80%;
      left: 0;
      right: 0;
      width: 0;
      height: 0;
      margin: auto;
      border-top: 4px solid #fff;
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      transition: .3s;
    }
    &:hover {
      .text_summary {
        opacity: 0;
      }
      .text_details {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
    }
    p {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      margin: auto;
      color: #fff;
    }
  }
  .text_summary {
    opacity: 1;
    @include fontsize(20);
    font-weight: bold;
    transition: .3s;
    @media ( max-width : 767px ) {
      @include fontsize(18);
    }
  }
  .text_details {
    opacity: 0;
    padding: 0 2em;
    line-height: 2;
    transition: .3s;
  }
}


// その他
figure.text_img {
  img {
    @media ( max-width : 767px ) {
      max-width: 380px !important;
      width: 100%;
    }
  }
}

figure {
  a:hover {
    img {
      opacity: .7;
      transition: .3s;
    }
  }
  +p {
    margin-top: 1.3em;
  }
}

table {
  +.map_container {
    margin-top: 2em;
  }
}

.text-middle {
  @include fontsize(20);
  @media ( max-width : 767px ) {
    @include fontsize(16);
  }
}

ul.sitemap {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #ccc;
  li {
    padding: 1em;
    border-bottom: 1px solid #ccc;
    a {
      @include fontsize(18);
      text-decoration: none;
      @media ( max-width : 767px ) {
        @include fontsize(16);
      }
      &:hover {
        color: $color_primary;
        text-decoration: none;
      }
    }
  }
}

// ======== Mobile
@media ( max-width : 480px ) {

} // @media

// ======== Tablet & PC
@media ( min-width : 768px ) {

} // @media

// ======== PC
@media ( min-width : 1024px ) {

} // @media
