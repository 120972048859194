@charset "utf-8";

table {
  margin: 0 auto;

  td,
  th {
    padding: 0.7em 1em;
    @media ( max-width : 767px ) {
      padding: 0.7em .3em;
    }
  }

  th {
    text-align: center;
    font-weight: normal;
  }
}
// デフォルトテーブル(defaut table)
.table-default {
  width: 100%;

  caption {
    margin-bottom: 0.5em;
    text-align: center;
    @include fontsize(20);
  }

  td,
  th {
    border: 1px solid $color_table_border;
  }

  th {
    background: $color_table_th_bg;
    color: $color_table_th_text;
  }

  td {
    background: $color_table_td_bg;
  }
}
// メニューテーブル(menu table)
.table-menu {
  width: 100%;

  caption {
    margin-bottom: 0.5em;
    text-align: center;
    @include fontsize(20);
  }

  td,
  th {
    padding: 0.7em 1em;
    border-top: 1px solid $color_menutable_border;
    border-bottom: 1px solid $color_menutable_border;
    @media ( max-width : 767px ) {
      padding: 0.7em .3em;
    }
  }

  tbody th {
    text-align: left;
  }

  td:last-child {
    text-align: right;
    white-space: nowrap;
  }
}
// 診療時間テーブル(table 診療時間)
.table-schedule {
  width: 100%;

  caption {
    margin-bottom: 0.5em;
    text-align: center;
    @include fontsize(20);
  }

  td,
  th {
    border: 1px solid $color_schedule_border;
    padding: 0.7em 1em;
    text-align: center;
    @media ( max-width : 767px ) {
      padding: 0.7em .3em;
    }
  }

  thead th {
    background: $color_schedule_thead_bg;
    color: $color_schedule_thead_text;
  }

  tbody th {
    background: $color_schedule_tbody_th_bg;
  }

  td {
    background: $color_schedule_td_bg;
  }
}

.table01 {
  width: 100%;
  td, th {
    border-bottom: 1px solid $color_menutable_border;
    padding: .9em .5em;
  }
  th {
    text-align: left;
    font-weight: bold;
  }
  &.combine {
    tr:last-child {
      td, th {
        border-bottom: 1px solid $color_menutable_border;
      }
    }
  }
  &.responsive-stack {
    @media ( max-width : 767px ) {
      th {
        border-bottom: 1px solid #ccc !important;
    padding: 0.5em .5em;
      }
    }
  }
}

// レスポンシブテーブル（767以下縦積み表示）(responsive table(hiển thị theo hướng dọc dưới 767))
@media (max-width: 767px) {
  .responsive-stack {
    display: block;

    thead {
      display: none;
    }

    tbody,
    td,
    th,
    tr {
      display: block;
    }

    td,
    th {
      width: 100% !important;
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 0;
      }
    }

    tr:last-child {
      :not(:last-child) {
        border-bottom: 0;
      }
    }
  }
}
// レスポンシブテーブル（767以下リスト表示）(responsive table(hiển thị list dưới 767))
@media (max-width: 767px) {
  .responsive-list {
    display: block;

    thead {
      display: none;
    }

    tbody,
    td,
    th,
    tr {
      display: block;
    }

    tr:not(:last-child) {
      td {
        border-bottom: 0;
      }
    }

    td {
      position: relative;
      padding-left: 40%;
      white-space: normal;
      text-align: left;

      &:first-child {
        border-bottom: 0;
      }

      &:last-child {
        border-top: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-top: 0;
        border-bottom: 0;
      }

      &:before {
        content: attr(data-title);
        position: absolute;
        top: 6px;
        left: 6px;
        width: 35%;
        padding-right: 10px;
        white-space: normal;
        text-align: left;
        font-weight: bold;
      }
    }
  }
}
// レスポンシブテーブル（767以下スクロール表示）(responsive table(hiển thị scroll dưới 767))
@media (max-width: 767px) {
  .responsive-scroll-container {
    display: block;
    width: 100%;
    min-height: 0.01%;
    overflow-x: scroll;

    .responsive-scroll-inner {
      width: 798px;
      padding: 0 15px 15px;
    }

    table {
      width: 768px;
      margin: 0;
    }
  }
}
// SP連結テーブル(table liên kết SP)
@media (max-width: 767px) {
  table.combine {
    tbody tr:last-child {
      td,
      th {
        border-bottom: 0;
      }
    }
  }
}
