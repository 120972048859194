@charset "utf-8";

@media ( max-width : 767px ) {

.sp_image_cols {
  &:not(.sp_col2):not(.sp_col3) { // 画像左+説明右(ảnh bên trái+giải thích bên phải)
    .col {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      figure {
        overflow: hidden;
        img {
          float: left;
          width: 32%;
          margin-bottom: 10px;
          vertical-align: middle;
        }
        figcaption {
          float: right;
          width: 66%;
          margin-top: 0;
          vertical-align: middle;
        }
      }
    }
  }
  &.sp_col2 { // 2カラム画像上+説明下(ảnh 2 column ở phía trên+giải thích ở phía dưới )
    .col {
      width: 49%;
      &:nth-child(odd) {
        clear: both;
        float: left;
      }
      &:nth-child(even) {
        float: right;
      }
    }
  }
  &.sp_col3 { // 3カラム画像上+説明下(ảnh 3 clomun ở trên +giải thích phía dưới)
    .col {
      float: left;
      width: 32%;
      &:nth-child(3n+1) {
        clear: both;
        margin-right: 2%;
      }
      &:nth-child(3n) {
        float: right;
      }
    }
  }
}

.sp_image_left {
  overflow: hidden;
  .col {
    &:first-child {
      display: inline-block;
      float: left;
      width: 32%;
      margin-right: 2%;
      margin-bottom: 0.5em;
    }
    &:last-child {
      float: none;
    }
  }
}

.sp_image_right {
  overflow: hidden;
  .col {
    &:first-child {
      display: inline-block;
      float: right;
      width: 32%;
      margin-left: 2%;
      margin-bottom: 0.5em;
    }
    &:last-child {
      float: none;
    }
  }
}

}
