@charset "utf-8";

footer {
  position: relative;
  .row {
    &:first-child {
      @media ( max-width : 767px ) {
        padding: 1em 0;
      }
    }
  }

  nav {
    text-align: center;

    ul {
      @include listclear();

      li {
        + li {
          border-top: 1px solid $color_ftnav_border_sp;
        }
      }
    }

    a {
      display: block;
      padding: 0.7em 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .copyright {
    text-align: center;
    background: $color_footer_bg;
    color: $color_footer_text;

    a {
      color: $color_ftnavlink;

      &:hover {
        color: $color_ftnavlink_hover;
      }
    }

    p {
      padding: 1em 0.3em;
    }

    ul {
      @include listclear();

      li {
        + li {
          border-top: 1px solid $color_ftnav_border_sp;
        }
      }
    }

    a {
      display: block;
      padding: 0.7em 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .more {
    text-align: center;
    a {
      max-width: 100%;
      @media ( min-width : 1000px ) {
        width: 236px;
      }
      @media ( max-width : 767px ) {
        width: 236px;
      }
    }
  }
}
@media ( min-width : 768px ) {
  footer {
    background: url("../img/foot_bg.jpg") no-repeat center top;
    padding-top: 200px;

    .row:not(:last-child) {
      margin-bottom: 5px;
    }

    nav {
      text-align: left;

      ul {
        @include list-horizontal();
        white-space: normal;

        li {
          line-height: 1.5;
          white-space: normal;

          + li {
            border: 0;
            padding-left: 20px;
          }
        }
      }

      a {
        display: inline;
        padding: 0;
      }
    }

    .footer_nav {
      margin-bottom: 35px;

      p:not(.more) {
        line-height: 1.9;
        @include fontsize(12);
        margin: 0 22px 0 0;
        font-weight: bold;
      }

      a {
        &:not(.add_arrow) {
          background: url("../img/gnav_icon.png") no-repeat left center;
          padding-left: 10px;
          text-decoration: none;
          @include fontsize(12);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .nav_pri {}

    .nav_sec {
      padding: 10px 0 0;
      overflow: hidden;

      > * {
        float: left;
      }
    }

    .copyright {
      ul {
        @include listclear();
      }

      li {
        float: left;
        padding: 15px 0;

        +li {
          border: 0!important;
          padding-left: 20px;
        }
      }

      a {
        background: url("../img/gnav_icon_white.png") no-repeat left center;
        padding-left: 10px;
        text-decoration: none;
        @include fontsize(12);

        &:hover {
          text-decoration: underline;
        }
      }
      p {
        padding: 1.4em 0.3em;
      }
    }
  }
}
