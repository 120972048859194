@charset "utf-8";
.pagetop {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 100;
  a {
    @include image-replace();
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    @media ( min-width : 768px ) {
      width: 70px;
      height: 70px;
    }
    padding: 0;
    border-radius: 50%;
    background: $color_pagetop_bg;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: auto;
      width: 10px;
      height: 10px;
      border-top: 2px solid $color_pagetop_arrow;
      border-left: 2px solid $color_pagetop_arrow;
      transform: translate(-50%, -20%) rotate(45deg);
      transition: .2s;
    }
  }
}
footer {
  &.high {
    .pagetop {
      position: absolute;
      bottom: auto;
      right: 5%;
      top: 0;
      margin: 0;
      transform: translateY(-50%);
      @if $pagetop_type == 'C' {
        transform: none;
      }
    }
  }
}
/*
 * ページトップのデザインタイプ(type design của top page)
 */
@if $pagetop_type == 'B' {
  @media ( min-width : 768px ) {
    .pagetop {
      a {
        border: none;
        background: transparent;
        &:after {
          width: 40px;
          height: 40px;
          border-top: 4px solid $color_pagetop_bg;
          border-left: 4px solid $color_pagetop_bg;
          transition: .2s;
        }
        &:hover {
          &:after {
            top: 40%;
          }
        }
      }
    }
  }
} @else if $pagetop_type == 'C' {
  @media ( min-width : 768px ) {
    .pagetop {
      position: absolute;
      a {
        border: none;
        background: transparent;
        @media ( min-width : 768px ) {
          width: 70px;
          height: 70px;
        }
        &:after {
          width: 30px;
          height: 30px;
          border-top: 4px solid $color_pagetop_arrow;
          border-left: 4px solid $color_pagetop_arrow;
          transition: .2s;
        }
        &:hover {
          &:after {
            top: 40%;
          }
        }
      }
    }
  }
}
