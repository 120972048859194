@charset "utf-8";

.slider-pro.wide {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto 10px;

  // buttons
  .sp-buttons {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    width: auto;
    padding-top: 0;
    text-align: right;
  }
  .sp-button {
    border: none;
    background: $color_slider_button;
  }
  .sp-selected-button {
    background: $color_slider_button_active;
  }

  // arrow
  .sp-previous-arrow,
  .sp-next-arrow {
    z-index: 2;
  }
  .sp-next-arrow:after,
  .sp-next-arrow:before,
  .sp-previous-arrow:after,
  .sp-previous-arrow:before {
    color: $color_sliderB_arrow;
  }

  .hps-slider-mask {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.stripe {
      background-color: $color_slider_mask_bg;
      background-image: url(../img/mask_stripe.png);
    }
  }
  .hps-slider-copy {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 50px;
    color: $color_slider_layer;
    text-align: left;
    @include fontsize(12);
    .lead {
      margin: 0;
      @include fontsize(18);
      line-height: 1;
      text-align: center;
      color: $color_slider_layer;
    }
  }
  &.disable-arrows .hps-slider-copy {
    padding: 0 10px;
  }
  .hps-slider-scroll {
    display: none;
  }

}


@media ( max-width : 767px ) {

.slider-pro.wide {
  &.sp-disable-arrows .hps-slider-copy {
    padding: 0 10px;
  }
}

} // @media


@media ( min-width : 768px ) {

.slider-pro.wide {

  // buttons
  .sp-buttons {
    bottom: 20px;
    right: 25px;
  }
  .sp-button {
    width: 18px;
    height: 18px;
    margin: 0 10px;
  }

  .hps-slider-mask {
  }
  .hps-slider-copy {
    text-align: center;
    @include fontsize(17);
    line-height: 1.8;
    .lead {
      @include fontsize(46);
    }
  }
  // SCROLLボタン(button SCROLL)
  .hps-slider-scroll {
    display: block;
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    text-align: center;
    a {
      display: inline-block;
      width: 100px;
      padding-bottom: 45px;
      background: url(../img/arrow_down_white.png) center bottom no-repeat;
      text-align: center;
      color: #fff;
      @include fontsize(12);
      text-decoration: none;
    }
    &.text-black {
      a {
        background: url(../img/arrow_down_black.png) center bottom no-repeat;
        color: #000;
      }
    }
  }

}

} // @media


@media ( min-width : 1024px ) {

.slider-pro.wide {
  .hps-slider-scroll {
    display: block;
    bottom: 45px;
  }
}

}
