@charset "utf-8";


@mixin calendar_arrow( $position ) {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    margin: auto;
    width: 6px;
    height: 6px;
    border-top: 1px solid $effect_arrow_color;
    transition: .2s;
  }
  &:after {
    @if $position == 'left' {
      transform: translate(0, -50%) rotate(-45deg);
      border-left: 1px solid $effect_arrow_color;
      left: 15%;
    } @else if $position == 'right' {
      transform: translate(0, -50%) rotate(45deg);
      border-right: 1px solid $effect_arrow_color;
      right: 15%;
    }
  }
  &:hover {
    &:after {
      @if $position == 'left' {
        left: 2%;
      } @else if $position == 'right' {
        right: 2%;
      }
    }
  }
}

.calendar_wrap {
  position: relative;
  padding: 4em 1.8em 2em;
  display: inline-block;
  background: rgba($color_primary, .05);
  @include fontsize(10);
  caption {
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    margin: auto;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
    thead,
    tbody {
    }
    thead {
      th {
        background: $color_table_th_bg;
        color: $color_table_th_text;
        border: 1px solid $color_table_border;
        border-bottom: none;
      }
    }
    tfoot {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      margin: auto;
      #prev {
        a {
          width: 26px;
          height: 25px;
          @include image-replace();
          display: block;
          position: absolute;
          top: 0;
          left: 40px;
          @include calendar_arrow('left');
        }
      }
      #next {
        a {
          width: 26px;
          height: 25px;
          @include image-replace();
          display: block;
          position: absolute;
          top: 0;
          right: 40px;
          @include calendar_arrow('right');
        }
      }
    }
    tbody {
      td {
        text-align: center;
        background: $color_body_bg;
        border: 1px solid $color_table_border;
      }
    }
  }
}


@media (min-width: 768px) {
  .column_sub {
    .calendar_wrap {
      padding: 40px 5px 5px;
      width: 100%;
      margin: 0 auto 1em;
      caption {
        top: 10px;
        @include fontsize(14);
      }
      table {
        width: 100%;
        th, td {
          padding: 0.5em .2em;
        }
        tfoot {
          #prev {
            a {
              width: 18px;
              height: 18px;
              top: 0;
              left: 20px;
            }
          }
          #next {
            a {
              width: 18px;
              height: 18px;
              top: 0;
              right: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .calendar_wrap {
    padding: 40px 5px 5px;
    width: 100%;
    caption {
      top: 10px;
    }
    table {
      width: 100%;
      th, td {
        padding: 0.5em .2em;
      }
      tfoot {
        #prev {
          a {
            width: 12px;
            height: 12px;
            top: 0;
            left: 20px;
          }
        }
        #next {
          a {
            width: 12px;
            height: 12px;
            top: 0;
            right: 20px;
          }
        }
      }
    }
  }
}
